import { createLinks, getPrioritizedAttributes, mergeAttributes } from '@/utils/links/linkHelpers';
import formatPostCode from '@/utils/urlHash/formatPostCode';
import SearchBarDefaults from '@/constants/SearchBar';

interface LinkObjectOptions {
  searchRequestObject?: Partial<TSearchRequest>;
  newAttribute?: any;
  rangeAttributes?: any;
  isSingleSelect?: boolean;
  isSelected?: boolean;
  removeAllFilters?: boolean;
  removeAllAttributes?: boolean;
  removeAllOtherFilters?: boolean;
  categories?: { l1Category?: string; l2Category?: string };
  page?: number;
  sortOptions?: any;
  postcode?: string;
  distance?: number;
  seller?: string;
  view?: string;
  query?: string;
  pageSize?: number;
  searchInTitleAndDescription?: boolean;
  attributes?: any;
  allowSavedSearch?: boolean;
  bypassSpellingSuggestion?: boolean;
  isViewOptionsClicked?: boolean;
  isSuggestedSearch?: boolean;
  categoryRedirectedClientSide?: boolean;
  disableRedirect?: boolean;
  withAllAttributes?: boolean;
}

// Helper function to update search request object category data
const fromSearchRequestCategoryObject = ({
  l1Category,
  l2Category,
}: { l1Category?: string; l2Category?: string } = {}) => ({
  l1Category,
  l2Category,
});

// Generates the link object based on various parameters
export const generateLinkObject = ({
  searchRequestObject,
  newAttribute = {},
  rangeAttributes,
  isSingleSelect = false,
  isSelected = false,
  removeAllFilters = false,
  removeAllAttributes = false,
  removeAllOtherFilters = false,
  categories,
  page = 1,
  sortOptions,
  postcode,
  distance,
  seller,
  view,
  query,
  pageSize,
  searchInTitleAndDescription,
  attributes,
  allowSavedSearch,
  bypassSpellingSuggestion,
  isViewOptionsClicked = false,
  isSuggestedSearch = false,
  categoryRedirectedClientSide = false,
  disableRedirect,
  withAllAttributes,
}: LinkObjectOptions) => {
  const {
    distance: sroDistance,
    searchInTitleAndDescription: sroSearchInTitleAndDescription,
    seller: sroSeller,
    searchQuery: sroSearchQuery,
    attributes: sroAttributes,
    categories: sroCategories,
    sortOptions: sroSortOptions,
    attributeRanges: sroAttributeRanges,
    viewOptions: sroViewOptions,
    traits: sroTraits,
  } = searchRequestObject ?? {};

  const getDistance = () =>
    distance ??
    (distance === SearchBarDefaults.DISTANCE ? distance : (sroDistance as { distanceMeters?: number })?.distanceMeters);

  const getPostcode = () => postcode ?? (postcode === SearchBarDefaults.POSTCODE ? postcode : sroDistance?.postcode);

  const distanceMeters = getDistance();
  const requestPostcode = getPostcode();
  const inDescription = searchInTitleAndDescription ?? sroSearchInTitleAndDescription;

  const linkCategories = categories || fromSearchRequestCategoryObject(sroCategories);
  const { l1Category } = linkCategories;
  const showQueryInSeoFriendlyPart = removeAllFilters || !l1Category || isSuggestedSearch;

  const getQuery = () => (isSuggestedSearch ? query : (query ?? sroSearchQuery));

  const linkObjectAttributes =
    attributes ||
    mergeAttributes({
      attributes: sroAttributes,
      newAttribute,
      isSingleSelect,
      isSelected,
    });

  const prioritizedAttributes = getPrioritizedAttributes(
    linkObjectAttributes,
    searchRequestObject?.seoFriendlyAttributes,
    searchRequestObject?.seoFriendlyTextAttributes,
    withAllAttributes,
  );

  return {
    searchRequestObject,
    seller: seller || sroSeller,
    query: getQuery(),
    searchInTitleAndDescription: inDescription,
    attributes: linkObjectAttributes,
    categories: linkCategories,
    page,
    pageSize,
    sortOptions: sortOptions || sroSortOptions,
    rangeAttributes: rangeAttributes || sroAttributeRanges,
    postcode: requestPostcode ? formatPostCode(requestPostcode) : undefined,
    distance: distanceMeters,
    view: view || sroViewOptions?.kind,
    removeAllFilters,
    removeAllAttributes,
    removeAllOtherFilters,
    allowSavedSearch,
    bypassSpellingSuggestion,
    isViewOptionsClicked,
    showQueryInSeoFriendlyPart,
    traits: sroTraits,
    categoryRedirectedClientSide,
    disableRedirect,
    prioritizedAttributes,
  };
};

const linkGeneratorHelper = (opts: LinkObjectOptions) => createLinks(generateLinkObject(opts));

export default linkGeneratorHelper;

import React, { useContext } from 'react';

import HeaderTSX from '@/client/components/Header/Header';
import linkGeneratorHelper, { generateLinkObject } from '@/utils/links/linkGeneratorHelper';
import getClientPageQueryObject from '@/client/utils/getClientPageQueryObject';
import pushToRouter from '@/client/utils/pushToRouter';
import enrichHeaderConfig from '@/client/components/Header/utils/headerConfig';
import getCategoryFromId from '@/client/components/Header/utils/getCategoryFromId';
import isRemoveAllAttributes from '@/client/components/Header/utils/isRemoveAllAttributes';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import experiments from '@/utils/labs/experiments';
import CATEGORIES_L1 from '@/constants/categories';

// quick fix for prod issue.
// TODO: refinement needed.
export const getPromotionalHeader = (
  is25YearsEnabled: boolean,
  tenant: string,
  logoUrls: any,
  anniversaryLogosPath: any,
) => {
  const isMarktplaats = tenant === 'mp';
  return is25YearsEnabled && isMarktplaats ? anniversaryLogosPath : logoUrls;
};

const Header = (props: THeaderProperties) => {
  const { labsConfig } = useContext(EnvironmentContext);
  const {
    isSticky,
    showNavBar,
    navBar,
    showSearchBar,
    searchBar,
    searchCategoryId,
    searchCategoryOptions,
    searchRequestObject,
    reloadPageOnSearch,
    experiments: experimentData,
    xsrfToken,
    logoUrls,
  } = props;

  const { isFetchResultsOnceEnabled, isFetchResultsOnceAllCatEnabled } = experiments({
    labsConfig,
  });

  const withAllAttributes =
    isFetchResultsOnceAllCatEnabled ||
    (isFetchResultsOnceEnabled && searchRequestObject.categories?.l1Category?.id === CATEGORIES_L1.DAMES_KLEREN);

  React.useEffect(() => {
    const handleSearch = (e) => {
      e.preventDefault();

      const { seller, categories: SROCategory } = searchRequestObject;

      const {
        category: categoryId,
        distance,
        postcode,
        query,
        onTitleAndDescription,
        onPage: isSearchOnPage,
      } = e.detail;

      const searchCategory = getCategoryFromId(searchCategoryOptions, categoryId);
      const { l1Category, l2Category } = searchCategory;
      const isEmptyQuery = !query || query.length === 0;
      const isSiteWideSearch = !l2Category && !l1Category;
      const isEmptySearch = isEmptyQuery && isSiteWideSearch && !seller;

      if (isEmptySearch) {
        window.location.assign('/');
      } else {
        const options: any = {
          query,
          categories: searchCategory,
          searchInTitleAndDescription: onTitleAndDescription,
          postcode,
          distance,
          seller: isSearchOnPage ? seller : {}, // TODO VOS-6871
          removeAllAttributes: isRemoveAllAttributes(searchCategory, SROCategory),
          removeAllFilters: false,
          searchRequestObject,
          withAllAttributes,
        };

        const href = linkGeneratorHelper(options);

        if (reloadPageOnSearch || !!seller !== !!isSearchOnPage) {
          // TODO VOS-6871
          window.location.assign(href);
        } else {
          const pageQuery = getClientPageQueryObject(generateLinkObject(options));
          pushToRouter({
            query: pageQuery,
            href,
          });
        }
      }
    };

    const node = document.getElementsByTagName('body')[0];
    node.addEventListener('search', handleSearch);

    return () => {
      node.removeEventListener('search', handleSearch);
    };
  }, [searchRequestObject, searchCategoryOptions, reloadPageOnSearch, withAllAttributes]);

  const headerData: THeaderProperties = {
    isSticky,
    showSearchBar,
    showNavBar,
    navBar,
    searchBar,
    searchCategoryId,
    searchCategoryOptions,
    searchRequestObject,
    xsrfToken,
    experiments: experimentData,
    logoUrls,
  };

  // headerConfig is updated here when there is changes in search data client side
  const headerConfig = enrichHeaderConfig(headerData);

  return <HeaderTSX headerConfig={headerConfig} />;
};

export default Header;
